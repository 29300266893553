:root {
    --textgray: #737b7d;
    --textBlack: #2e373e;
    --textRed: #ff475e;
    --mainColorGray: #9eacbc;
    --mainColorGray40: #c4cdd7;
    --mainColorBlack: #373f41;
    --mainColorBlue: #199cff;
    --mainGradient: linear-gradient(-90deg, rgba(47, 128, 237, 1) 0%, rgba(86, 204, 242, 1) 100%);
    --mainGradient90: linear-gradient(0deg, rgba(47, 128, 237, 1) 0%, rgba(86, 204, 242, 1) 100%);
    --gray80: #222830;
    --bgWhite: #ffffff;
    --bgGray: #f5f5f5;
}
.mb5 {
    margin-bottom: 5px !important;
}
.mr16 {
    margin-right: 16px !important;
}
.mt64 {
    margin-top: 64px !important;
}
.mb64 {
    margin-bottom: 64px !important;
}
.center {
    text-align: center !important;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    color: var(--textBlack);
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.link_list {
    margin: 40px;
}
.link_list a {
    color: #1976d2;
    text-decoration: underline;
    display: inline-block;
    padding: 10px;
}
.link_list a:hover {
    text-decoration: none;
}

.introduction,
.error {
    padding-top: 96px;
    display: flex;
    justify-content: center;
    padding-bottom: 200px;
}
.introduction0 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.introduction0 .button-field {
    margin-top: 40px !important;
}
.introduction .logo,
.error .logo {
    width: 300px;
    height: 105px;
    margin-bottom: 48px;
    position: relative;
}
.introduction .page-title,
.error .page-title {
    text-align: center;
    margin-bottom: 96px;
    font-weight: 600;
}
.error .page-title {
    margin-bottom: 20px;
}
.attendance .page-title {
    margin-bottom: 96px;
    font-weight: 600;
}
.error .page-detail {
    text-align: center;
    margin-bottom: 56px;
    color: var(--mainColorGray);
}
.error .gototop {
    width: 272px;
}
.text-field {
    margin-bottom: 32px;
}
.text-field-double {
    margin-bottom: 32px;
}
.text-field .MuiOutlinedInput-root,
.dialog .MuiOutlinedInput-root {
    width: 356px !important;
}
.text-field-double .MuiOutlinedInput-root {
    width: 170px !important;
}
.dialog .dialog-column .MuiOutlinedInput-root {
    max-width: 356px !important;
    width: 100% !important;
}
.button-field,
.button-field.MuiDialogActions-root {
    text-align: center;
    display: flex;
    justify-content: center;
}
.button-field button {
    height: 56px;
    width: 356px;
    background: var(--mainColorBlue);
    border-radius: 40px;
    box-shadow: none;
}
.button-field button.gray {
    background: #fff;
    color: var(--mainColorBlue);
}
.table-field .MuiDataGrid-cell Button.gray {
    background: #fff;
    color: var(--mainColorBlue);
}
.step-field .MuiStepLabel-alternativeLabel {
    font-size: 11px !important;
}
.introduction h1 {
    font-size: 22px;
}
.gray {
    color: #a7a7a7;
}
.MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.MuiButtonBase-root-MuiRadio-root.Mui-checked,
.MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--mainColorBlue);
}
.MuiTabs-indicator {
    background: var(--mainColorBlue);
}
.Mui-checked {
    color: var(--mainColorBlue) !important;
}
.MuiInputLabel-shrink {
    color: var(--mainColorBlack);
    font-weight: 600;
}
.clip-field .MuiChip-root .MuiChip-icon {
    color: #ffc02a;
}
.introduction1 .text-field,
.setting1 .text-field {
    margin-bottom: 56px;
}
.introduction1 .hankyu-item,
.setting1 .hankyu-item {
    margin-bottom: 16px;
}
.setting3 .border-doted {
    padding-top: 64px;
}
.step-field {
    padding: 72px;
    margin-bottom: 24px;
    background: #fff;
    border-radius: 24px;
    display: flex;
}
.step-field .MuiStepper-root {
    min-width: 640px !important;
    margin: 0 auto;
}
.step-field .MuiStepIcon-root {
    width: 40px !important;
    height: 40px !important;
    color: var(--mainColorGray) !important;
}
.step-field .MuiStepConnector-root {
    top: 19px;
}
.step-field .MuiStepConnector-line {
    border-color: var(--mainColorGray);
    border-top-width: 3px;
}
.step-field .Mui-active .MuiStepConnector-line,
.step-field .Mui-completed .MuiStepConnector-line {
    border-color: var(--mainColorBlue) !important;
}
.step-field .MuiStepConnector-root {
    left: calc(-50% + 28px);
    right: calc(50% + 28px);
}
.step-field .MuiStepIcon-root.Mui-active circle {
    stroke: var(--mainColorBlue) !important;
    r: 11.52 !important;
    stroke-width: 1px !important;
    fill: transparent !important;
}
.step-field .Mui-active .MuiStepIcon-text {
    fill: var(--mainColorBlue) !important;
}
.step-field .Mui-completed {
    color: var(--mainColorBlue) !important;
}
.step-field .MuiStepLabel-label.Mui-active {
    font-weight: 600;
    color: var(--mainColorBlue);
}
.step-field .MuiStepLabel-labelContainer {
    color: var(--mainColorGray);
}
.step-field .MuiStepLabel-label.Mui-completed {
    color: var(--mainColorGray);
}

.section-title .no {
    display: inline-block;
    padding: 4px 0 5px;
    background: #d8eeff;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    text-align: center;
    color: var(--mainColorBlue);
    margin-right: 16px;
}

.section-title {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--mainColorGray40);
    margin-bottom: 48px;
}
.introduction1,
.introduction2,
.introduction3,
.introduction4,
.introduction5 {
    max-width: 870px;
    flex-grow: 1;
    padding: 0 20px;
}
.clip-field {
    display: flex;
    flex-direction: column;
}
.clip-field .MuiChip-root {
    width: 250px !important;
    height: 56px !important;
    border-radius: 28px !important;
    padding: 10px !important;
    margin-bottom: 32px !important;
    background: #fff;
    border: 1px solid var(--mainColorBlue);
    color: var(--mainColorBlue);
}
.clip-field .MuiChip-deleteIcon {
    color: #ffafaf !important;
    transition: 0.3s !important;
}
.clip-field .MuiChip-deleteIcon:hover {
    color: rgb(224, 0, 0, 0.6);
}
.long-clip .MuiChip-root {
    width: auto !important;
    max-width: 600px !important;
}
.clip-field .MuiChip-label {
    flex: 1 !important;
}
.clip-field .MuiButtonBase-root.MuiButton-root {
    width: 123px !important;
    background-color: var(--mainColorBlue);
    border-radius: 60px;
    box-shadow: none;
}

.MuiButtonBase-root-MuiIconButton-root {
    background: var(--mainColorGray) !important;
    width: 230px;
    height: 230px;
    border-radius: 5px !important;
}
.setting3 .img-inkan-field {
    margin-top: 84px;
}
.img-inkan-field .css-i4bv87-MuiSvgIcon-root {
    color: #fff;
    width: 1.5em;
    height: 1.5em;
}
.introduction .table-field {
    margin-bottom: 177px;
}
.table-field .MuiDataGrid-cellContent,
.table-field .MuiDataGrid-cell--withRenderer div {
    width: 100% !important;
    text-align: center !important;
}

.table-field .MuiDataGrid-cellContent,
.table-field .MuiDataGrid-cell--withRenderer div {
    text-align: center !important;
}
.table-field .MuiDataGrid-cell--withRenderer p {
    margin: 0 !important;
    text-align: center !important;
}
.table-field .MuiDataGrid-columnHeaders,
.table-field .MuiTableHead-root {
    background: var(--gray80) !important;
    color: #fff !important;
    border-radius: 0 !important;
}
.table-field .MuiPaper-root {
    box-shadow: none !important;
    border-radius: 0 !important;
}
.table-field .MuiTableHead-root .MuiTableCell-root {
    color: #fff !important;
    text-align: center !important;
}
.table-field .MuiTableBody-root .MuiTableCell-root {
    text-align: center !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}
.table-field .MuiDataGrid-columnSeparator {
    display: none !important;
}
.table-field .MuiDataGrid-root {
    border-right: none !important;
    border-left: none !important;
    border-radius: 0 !important;
}
.table-field .MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    padding: 20px 10px !important;
}
.table-field .MuiDataGrid-cell Button {
    height: 40px !important;
    width: 100px !important;
}
.table-field .MuiDataGrid-iconButtonContainer svg {
    color: #fff !important;
}
.border-doted {
    border-top: 1px dashed #cdcdcd;
}
.introduction1 .border-doted {
    margin-top: 80px;
    padding-top: 64px;
}
.introduction3 .time-field .MuiOutlinedInput-root,
.setting1 .time-field .MuiOutlinedInput-root,
.shukinbo-detail .time-field .MuiOutlinedInput-root {
    width: 100px !important;
    margin-right: 16px !important;
}
.introduction3 .time-field .time-minute,
.setting1 .time-field .time-minute,
.shukinbo-detail .time-field .time-minute {
    margin-left: 40px;
}
.introduction3 .stack,
.setting1 .stack {
    display: flex;
}
.section-wrapper {
    margin-bottom: 128px;
}
.introdaction4 .section-wrapper {
    margin-bottom: 0;
}
.introdaction4 .last-child {
    margin-bottom: 128px;
}
.address-field {
    display: flex;
    flex-direction: column;
}
.introduction .hankyu-field,
.setting1 .hankyu-field {
    margin-top: 10px;
}
.logged-in .setting1 .page-head {
    row-gap: 16px;
}
.dialog .MuiDialog-paper {
    padding: 56px 56px 64px;
}
.dialog h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 40px;
}
/* .dialog .text-field {
    margin-bottom: 24px;
} */
.dialog.large .MuiStack-root > :not(style) + :not(style),
.dialog.large .MuiStack-root > :not(style) + :not(style),
.hankyu-item .MuiStack-root > :not(style) + :not(style) {
    margin-top: 0 !important;
}
.dialog .check-field {
    margin-top: 24px;
    margin-bottom: 40px;
}
.dialog .check-field .MuiFormControlLabel-label {
    font-size: 14px !important;
    font-weight: 600 !important;
}
.dialog .button {
    font-size: 14px;
    font-weight: 600;
}
.dialog-yesno .MuiDialog-paper {
    padding: 20px !important;
}
.dialog-yesno .MuiDialogActions-root {
    justify-content: center !important;
}
.dialog-yesno button {
    width: 125px;
    border-radius: 60px;
}
.MuiButtonBase-root-MuiIconButton-root {
    top: 16px;
    right: 16px;
}
ul {
    list-style: none;
    padding-left: 0;
}
/* .kintai-shukinbo .table-field .active,
.kintai-shukinbo .table-field .MuiDataGrid-row.MuiDataGrid-row--lastVisible.MuiDataGrid-row--dynamicHeight {
    background: #ffeded;
} */
.kintai-shukinbo .table-field.table-sum .MuiDataGrid-row.MuiDataGrid-row--lastVisible.MuiDataGrid-row--dynamicHeight {
    background: unset !important;
}
.kintai-shukinbo .table-field.table-sum {
    margin-bottom: 20px !important;
}
.kintai-shukinbo .table-field.table-sum .MuiDataGrid-columnHeaders,
.kintai-shukinbo .table-field.table-sum .MuiTableHead-root {
    background-color: #1976d2 !important;
}
.news-field {
    margin-bottom: 96px;
}
.news-field ul .date {
    font-size: 14px;
    color: #373f41;
}
.news-field ul .title {
    font-size: 18px;
    color: #373f41;
    margin-top: 8px;
    display: flex;
}
.news-field ul .title .text {
    padding-left: 4px;
    box-sizing: border-box;
}
.news-field ul li {
    border-bottom: 1px solid #b7b7b6;
    position: relative;
    padding: 16px 0;
}
.news-field ul li a {
    display: block;
}
.news-field ul li a:after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}
.news-field ul li.noread .title {
    font-weight: 600;
}
.news-field ul li.noread .icon-noread {
    position: relative;
    width: 20px;
}
.news-field ul li.noread .icon-noread::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 4px;
    background: #8b0000;
}
.header {
    background: var(--bgWhite) !important;
    color: var(--textgray) !important;
}
.header .MuiSvgIcon-root {
    color: #b3bbc3 !important;
}

.wrapper {
    display: flex !important;
    background: var(--bgGray);
    flex-direction: column;
    padding: 24px;
    align-items: center;
    min-height: calc(100vh - 90px);
    box-sizing: border-box;
}
.menu {
    width: 258px !important;
    background: var(--bgWhite) !important;
}
.main-wrapper {
    flex: 1 1;
    background: var(--bgWhite);
    border-radius: 24px;
    width: 100%;
}
.logged-in {
    padding: 64px 48px 120px !important;
}
.logged-in .page-head .switch {
    display: flex !important;
    row-gap: 16px !important;
    column-gap: 16px !important;
    margin: 0 !important;
    font-size: 14px !important;
    color: var(--mainColorGray) !important;
    font-weight: 500 !important;
}
.logged-in .page-head .switch a {
    border-radius: 60px !important;
    font-size: 13px !important;
    padding: 12px 20px !important;
}
.logged-in .page-head .switch a.active {
    background-color: var(--mainColorBlue) !important;
    color: #fff !important;
}
.logged-in .page-head {
    display: flex !important;
    column-gap: 48px !important;
    align-items: center !important;
    margin-bottom: 63px !important;
    flex-wrap: wrap !important;
}
.logged-in .page-head.help-on {
    column-gap: 8px !important;
    margin-bottom: -13px !important;
}
.logged-in .page-head.help-on svg {
    color: #b8c8d1;
    cursor: pointer;
}
.logged-in .page-head .help-text {
    visibility: hidden;
    opacity: 0;
    font-size: 14px;
    transition: 0.2s;
    height: 0;
    transition: 0s;
}
.logged-in .help-text {
    visibility: hidden;
    opacity: 0;
    font-size: 14px;
    transition: 0.2s;
    margin-bottom: 43px !important;
}
.logged-in .page-head .help-text.display-on {
    visibility: visible;
    opacity: 1;
    font-size: 14px;
    height: auto;
    transition: 0.2s;
}
.logged-in .help-text.display-on {
    visibility: visible;
    opacity: 1;
    font-size: 14px;
}
.logged-in .page-head .help-text a {
    color: var(--mainColorBlue);
    text-decoration: underline;
}
.logged-in .help-text a {
    color: var(--mainColorBlue);
    text-decoration: underline;
}
.logged-in h1.page-title {
    margin-top: 0 !important;
    font-size: 28px !important;
    margin-bottom: 0 !important;
}
.logged-in h2 {
    font-size: 22px !important;
    margin-bottom: 56px !important;
}
.logged-in .attendance .message {
    font-size: 18px !important;
    font-weight: 600 !important;
}
.header .header-inner {
    padding: 8px 24px 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.header .logo {
    width: 140px !important;
    position: relative !important;
    height: 50px !important;
    margin-left: 36px !important;
}
.header .header-left {
    display: flex !important;
    align-items: center !important;
}
.header .company-name {
    margin-left: 24px !important;
    font-size: 14px !important;
}
.header .user-name {
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
}
.header .user-name span {
    display: inline-block !important;
}
.header .user-name .name {
    margin-right: 12px !important;
}
.header .user-name .css-i4bv87-MuiSvgIcon-root {
    color: var(--textgray) !important;
    width: 14px !important;
}
.nav {
    margin: 0 !important;
}
.nav .nav-item {
    position: relative !important;
    height: 72px !important;
}
.nav .nav-itemLink {
    font-size: 16px !important;
    padding: 24px 16px !important;
    color: var(--mainColorGray) !important;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
}
.nav .nav-item.active .nav-itemLink {
    color: var(--mainColorBlue) !important;
}
.nav .menu-name {
    margin-left: 8px !important;
    flex: 1 !important;
}
.nav .child-icon {
    position: relative !important;
    width: 24px !important;
    height: 100% !important;
    display: inline-block !important;
}
.nav .child-icon::before,
.nav .child-icon::after {
    content: '' !important;
    position: absolute !important;
}
.nav .child-icon::before {
    width: 5px !important;
    height: 5px !important;
    top: calc(50% - 2.5px) !important;
    left: calc(50% - 2.5px) !important;
    background: var(--mainColorGray) !important;
    border-radius: 50% !important;
}
.nav .child-icon::after {
    width: 2px !important;
    height: calc(100% + 48px) !important;
    top: -24px !important;
    left: calc(50% - 1px) !important;
    background: var(--mainColorGray) !important;
}
.nav .child-icon.last-item::after {
    height: calc(50% + 24px) !important;
}
.nav .nav-item.active .child-icon::before {
    content: none !important;
}
.nav .nav-item.active .child-icon::after {
    background: var(--mainColorBlue) !important;
    width: 3px !important;
    left: calc(50% - 1.5px) !important;
}
.tab-wrapper .inside.MuiBox-root {
    padding: 0 !important;
    padding-top: 72px !important;
}
.kintai-btn .button-field {
    justify-content: flex-start !important;
    margin-bottom: 56px !important;
}
.kintai-btn .button-field div {
    align-items: center !important;
}
.kintai-btn .button-field .time {
    margin-left: 56px !important;
}
.kintai-btn h2 {
    margin-top: 56px;
}
.select-year {
    min-width: 195px !important;
    width: unset !important;
}
.select-month {
    min-width: 119px !important;
    width: unset !important;
}
.select-user {
    min-width: 235px !important;
    width: unset !important;
}
.dialog .select-kitan-type {
    min-width: 232px !important;
    width: unset !important;
}
.table-link {
    color: var(--mainColorBlue) !important;
    text-decoration: underline !important;
}
.table-disabled {
    background-color: #c4c4c4;
}
.data-from-to button {
    height: 56px;
    min-width: 94px;
}
.search-field button {
    height: 40px;
    min-width: 94px;
}
.MuiStack-root .MuiFormControl-root {
    margin: 0 !important;
    margin-top: 0 !important;
}
.search-field div > :not(style) + :not(style),
.stack-wrap.css-padr08-MuiStack-root > :not(style) + :not(style) {
    margin: 0 !important;
    margin-top: 0 !important;
}
.css-1qhgljy-MuiStack-root > :not(style) + :not(style) {
    margin-left: 0 !important;
}
.MuiStack-root > :not(style) + :not(style),
.search-field .MuiStack-root > :not(style) + :not(style),
.stack-wrap.MuiStack-root > :not(style) + :not(style) {
    margin: 0 !important;
    margin-top: 0 !important;
}
.search-field {
    margin-bottom: 24px;
}
.search-field :first-child {
    align-items: center !important;
}
.flex-right {
    justify-content: flex-end;
}
.flex-left {
    justify-content: flex-start;
}
.flex-between {
    justify-content: space-between;
}
.logged-in .h1 {
    font-size: 28px !important;
}
.detail-page .user-data span {
    display: block;
}
.detail-page .user-data {
    font-size: 14px;
    font-weight: 600;
}
.detail-page .user-data .large {
    font-size: 18px;
    font-weight: 600;
}
.detail-page .user-data span {
    margin-bottom: 8px;
}
.detail-page .user-data .mb-large {
    margin-bottom: 40px;
}
.detail-page .user-data .mb-middle {
    margin-bottom: 32px;
}
.detail-page .user-data {
    padding: 32px 32px;
    border: 1px solid var(--textgray);
    border-radius: 5px;
    width: 100%;
}
.flex-field {
    display: flex;
    column-gap: 56px;
    flex-wrap: wrap;
    row-gap: 20px;
}
.flex-field > div {
    width: calc((100% - 56px) / 2);
}
.flex-field .button-field {
    text-align: left;
    justify-content: flex-start;
}
.shukinbo-detail .flex-field {
    margin-bottom: 64px;
}
.shukinbo-detail .table-field {
    margin-bottom: 56px;
}
.shukinbo-detail .border-doted {
    margin-top: 64px;
    padding-top: 64px;
}
.shukinbo-detail-edit .use-data-edit .right-box {
    max-width: 320px;
}
.shukinbo-detail-edit .use-data-edit .right-box .user-data {
    height: 100%;
}
.shukinbo-detail-edit .flex-field {
    column-gap: 26px;
}
.shukinbo-detail-edit .user-data table tr {
    vertical-align: baseline;
}
.shukinbo-detail-edit .user-data table td,
.shukinbo-detail-edit .time-chosei-data td {
    padding-right: 14px;
}
.shukinbo-detail-edit .table-edit.flex-field > div {
    width: calc((100% - (2% + 52px)) / 2);
}
.shukinbo-detail-edit .table-edit.flex-field .center-box {
    width: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shukinbo-detail-edit .button-field {
    justify-content: space-between;
}
.shukinbo-detail-edit .button-field button {
    width: 200px;
}
.arrow02 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #4a4a4a;
}
.dialog.large .MuiDialog-paper {
    max-width: 867px;
    max-height: calc(100% - 24px);
}
.dialog.large .MuiOutlinedInput-root {
    width: 345px !important;
}
.table-field .page-number {
    color: var(--textgray);
    font-size: 11px;
    margin-bottom: 8px;
}
.button-field.paging {
    margin-top: 36px;
}
.button-field.paging button {
    height: 40px;
    width: 72px;
}
.search-field button,
.button-field.paging button,
.table-field .MuiDataGrid-cell Button,
.data-from-to button {
    font-size: 13px;
    background: var(--mainColorBlue);
    border-radius: 60px;
    box-shadow: none;
}
.table-field .MuiDataGrid-cell Button.dropmenu-btn {
    width: 40px;
    min-width: 0;
}
.table-field .MuiDataGrid-cell Button + .dropmenu-btn {
    margin-left: 8px;
}
.comment {
    font-size: 11px;
    font-weight: 600;
}
.comment.red,
.table-red {
    color: var(--textRed);
}
.yukyu-info {
    margin-bottom: 48px;
}
.yukyu-info th {
    text-align: left;
}
.yukyu-info td {
    font-weight: 600;
}
.yukyu-info .name {
    font-size: 18px;
}
.yukyu-info .data,
.yukyu-info .reason {
    font-size: 14px;
}
.yukyu-tab2 .comment {
    margin-bottom: 7px;
}
.dialog .MuiOutlinedInput-root.small {
    width: 100px;
}
.dialog .MuiOutlinedInput-root.disabled {
    background-color: #f2f2f2;
}
.dialog .radio-field {
    margin-top: 8px;
    margin-bottom: 40px;
}
.radio-btn button {
    width: 110px !important;
    font-size: 14px !important;
}
.radio-btn .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    margin: 0;
    padding: 0;
}
.kara {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
}
.yukyu-shinsei .text-field,
.shukinbo-off .text-field {
    margin-bottom: 0;
}
.item-flex-end {
    align-items: flex-end;
}
.yukyu-shinsei .section-title {
    margin-bottom: 16px;
}
.yukyu-shinsei .text-field .MuiOutlinedInput-root {
    width: 296px !important;
}
.nav .sp-nav-item {
    padding: 24px 16px 24px 30px;
    font-size: 13px;
    color: var(--mainColorGray);
    height: auto !important;
    position: relative !important;
    display: none;
}
.nav .sp-nav-item::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 18px;
    width: 2px;
    height: calc(100% - 36px);
    background-color: #b3bbc3;
}
.nav .sp-nav-item .sp-user-name {
    margin-top: 5px;
    display: inline-block;
}
.btn-flex {
    display: flex;
    gap: 16px;
}
/* 1100px以下 */
@media screen and (max-width: 1100px) {
    .time-data-edit {
        flex-direction: column;
    }
    .shukinbo-detail-edit .table-edit.flex-field > div {
        width: 100%;
    }
    .shukinbo-detail-edit .table-edit.flex-field .center-box {
        width: 100%;
    }
    .shukinbo-detail-edit .table-edit.flex-field .center-box .arrow02 {
        transform: rotate(90deg);
    }
}
/* 900px以下 */
@media screen and (max-width: 899px) {
    .dialog.large .stack-wrap .text-field {
        margin-bottom: 8px;
    }
    .dialog.large .stack-wrap {
        margin-bottom: 24px;
    }
    .shukinbo-off .item-flex-end,
    .yukyu-shinsei .item-flex-end {
        align-items: unset;
    }
    .shukinbo-off .kara,
    .yukyu-shinsei .kara {
        display: none;
    }
    .yukyu-shinsei .data-from-to button {
        max-width: 296px;
    }
}
/* 832px以下 */
@media screen and (max-width: 832px) {
    .step-field {
        width: 100% !important;
        max-width: 784px !important;
        padding: 20px !important;
    }
    .step-field .css-10mg1vw-MuiStepper-root {
        min-width: unset !important;
        width: 100% !important;
    }
    .company-name,
    .user-name {
        /* display: none !important; */
        visibility: hidden !important;
    }
    .nav .sp-nav-item {
        display: block !important;
    }
}
/* 800px以下 */
@media screen and (max-width: 799px) {
    .flex-field {
        flex-direction: column !important;
    }
    .flex-field > div {
        width: 100% !important;
    }
    .shukinbo-detail-edit .use-data-edit .right-box {
        width: 100% !important;
        max-width: unset !important;
    }
    .shukinbo-detail-edit .button-field {
        flex-direction: column !important;
        row-gap: 16px !important;
    }
    .shukinbo-detail-edit .button-field button {
        width: 100% !important;
    }
}
/* 600px以下 */
@media screen and (max-width: 599px) {
    .kintai-btn .button-field .time {
        margin-left: 0 !important;
        text-align: left !important;
        margin-top: 5px !important;
    }
}
/* 500px以下 */
@media screen and (max-width: 500px) {
    .text-field .MuiOutlinedInput-root,
    .dialog .MuiOutlinedInput-root,
    .introduction .logo,
    .error .logo,
    .button-field button,
    .yukyu-shinsei .data-from-to button,
    .yukyu-shinsei .text-field .MuiOutlinedInput-root {
        width: 100% !important;
        max-width: unset !important;
    }
    .main-wrapper {
        padding: 40px 20px 40px !important;
    }
    .main-wrapper > div,
    .button-field > div,
    .dialog-column .button-field {
        flex-grow: 1;
        align-items: unset;
    }
    /* .step-field .MuiStepper-root {
        min-width: unset !important;
        margin: 0 auto;
    } */
    .step-field .MuiStepIcon-root {
        width: 30px !important;
    }
    .introduction3 .stack,
    .setting1 .stack {
        flex-direction: column !important;
    }
    .introduction3 .time-field .time-minute,
    .setting1 .time-field .time-minute,
    .shukinbo-detail .time-field .time-minute {
        margin-left: 0 !important;
    }
    .dialog-column .MuiDialog-paper {
        padding: 56px 24px 64px !important;
        width: 100% !important;
    }
    .btn-flex {
        display: flex !important;
        flex-direction: column !important;
        gap: 16px !important;
    }
}

/* -------- EXTRA -------- */
.nav .active::after {
    background: var(--mainColorBlue) !important;
}
.nav .active::before {
    background: var(--mainColorBlue) !important;
}
.hidden {
    display: none !important;
}
.hidden-visibility {
    visibility: hidden !important;
}
.time-field-text {
    margin-left: 0px !important;
    padding-top: 15px !important;
}
.link-forget {
    text-align: center;
    margin-top: 30px;
}
.link-forget a {
    color: #1b8af6;
    font-size: 90%;
}
.attendance_day_weekday {
    color: #000000;
    font-size: 12px;
    /* padding-right: 3px; */
    font-weight: bold;
}
.attendance_day_saturday {
    color: #3d85c6;
    font-size: 12px;
    /* padding-right: 3px; */
    font-weight: bold;
}
.attendance_day_sunday {
    color: #ea3423;
    font-size: 12px;
    /* padding-right: 3px; */
    font-weight: bold;
}
.attendance_day_holiday {
    color: #ea3423;
    font-size: 12px;
    /* padding-right: 3px; */
    font-weight: bold;
}
.error-application {
    color: red;
    font-size: 12px;
    padding-right: 3px;
    font-weight: bold;
}
.error-validation {
    color: red;
    font-size: 12px;
    position: absolute;
}
.error-validation-flex {
    color: red;
    font-size: 12px;
    margin-bottom: -30px;
    flex-direction: column;
}
.error-validation-flex-multiple {
    color: red;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    flex-direction: column;
}
.error-validation-flex-margin-top {
    color: red;
    font-size: 12px;
    margin-bottom: -30px;
    margin-top: 0px;
    flex-direction: column;
}
.error-validation-margin-top {
    color: red;
    font-size: 12px;
    position: absolute;
    margin-top: 0px;
}
.introduction .datagrid-header {
    background: #373f41;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
}
.introduction .datagrid-header-hide-separator .MuiDataGrid-columnSeparator {
    display: none !important;
}
.introduction .datagrid-rows {
    background: #f0f0f0 !important;
    line-height: 2em !important;
    font-size: 13px !important;
    font-weight: 400 !important;
}
.datagrid-rows {
    background: #f0f0f0;
    line-height: 2em;
    font-size: 13px;
    font-weight: 400;
}
.datagrid-application-rows {
    background: #ffeded;
    line-height: 2em;
    font-size: 13px;
    font-weight: 400;
}
.introduction .img-inkan-field div button {
    height: auto !important;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.text-field .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
}
.Mui-error.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.23);
}

.MuiInputLabel-shrink {
    color: #373f41;
    font-weight: 600;
}

/* .MuiDataGrid-row,
.MuiDataGrid-root .MuiDataGrid-cell,
.rendering-zone {
    max-height: none !important;
}
.MuiDataGrid-root .MuiDataGrid-window {
    position: relative !important;
}
.MuiDataGrid-root .MuiDataGrid-viewport {
    max-height: none !important;
}
.MuiDataGrid-root {
    height: auto !important;
} */

/* .MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
    max-height: fit-content !important;
}
.MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    max-height: inherit;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    max-height: inherit;
    width: 100%;
    white-space: initial;
    line-height: 16px;
} */
